import React, {createContext, ReactNode, useCallback, useContext, useEffect, useState} from 'react';
import axios, {AxiosRequestConfig, AxiosResponse} from 'axios';
import {useNavigate} from "react-router-dom";

interface DataContextType {
    currentUser: User;
    fetchAppointments: (from?: string | null, to?: string | null) => Promise<AppointmentItem[]>;
    updateAppointment: (
        requestType: string,
        data: AppointmentItem | AppointmentItem[],
        onSuccess?: (response: AxiosResponse) => void,
        onError?: (error: any) => void
    ) => Promise<boolean | undefined>;
    fetchUser: () => Promise<void>;
}

interface DataProviderProps {
    children: ReactNode;
}

export const sendRequest = async (endPoint: string, method: string, data: string | null = null, navigate: (path: string) => void): Promise<AxiosResponse<any, any>> => {
    const apiUrl = process.env.REACT_APP_API_URL;

    let oktaToken = JSON.parse(localStorage.getItem("okta-token-storage") || "{}");

    const oktaTokenUndefined = oktaToken === null || oktaToken === "{}" || oktaToken.idToken === undefined || oktaToken.idToken.idToken === undefined;
    if (oktaTokenUndefined) {
        navigate("/");
        return {} as AxiosResponse;
    }

    const config: AxiosRequestConfig = {
        method: method,
        url: `${apiUrl}${endPoint}`,
        headers: {
            Authorization: `Bearer ${JSON.parse(localStorage.getItem('okta-token-storage') || '{}').idToken.idToken}`,
            'Content-Type': 'application/json',
        }
    };
    if (data) {
        config.data = data;
    }

    try {
        return await axios(config);
    } catch (error) {
        console.error('Error sending request:', error);
        throw error;
    }
};

const DataContext = createContext<DataContextType | undefined>(undefined);

export const DataProvider: React.FC<DataProviderProps> = ({children}) => {
    const [currentUser, setCurrentUser] = useState<User>({} as User);
    const navigate = useNavigate();

    const fetchUser = useCallback(async () => {
        try {
            const response = await sendRequest('/users/currentUser', 'get', null, navigate)


            if (response === null
                || response?.data === null
                || response?.data === undefined
                || !response?.data
                || response?.data === "{}") {
                console.error('Error fetching current user: ', response);
                navigate("/");
            }

            console.log(response.data);

            setCurrentUser(response?.data);
        } catch (error) {
            // This happens when the person's authentication that is cached got expired. Redirect to login page
            if (isUnauthorizedError(error)) {
                navigate("/");
                return;
            }
            navigate("/");
            console.error('Error fetching current user: ', error);
        }
    }, [navigate]);

    useEffect(() => {
        const getUser = async () => {
            await fetchUser();
        };

        getUser();
    }, [fetchUser]);

    function isUnauthorizedError(error: unknown): error is { response?: { status?: number } } {
        return (error as { response?: { status?: number } }).response?.status === 401;
    }

    const fetchAppointments = useCallback(async (from: string | null = null, to: string | null = null) => {
        let url = `/appointments`;

        if (from && to) {
            url += `/reports?from=${from}&to=${to}`;
        } else if (from) {
            url += `?from=${from}`;
        }

        try {
            const response = await sendRequest(url, 'get', null, navigate);
            return response?.data.sort((a: AppointmentItem, b: AppointmentItem) => Date.parse(a.startTime) - Date.parse(b.startTime));
        } catch (error) {
            console.error('Error fetching appointments:', error);
            throw error;
        }
    }, [navigate]);

    /**
     * Update or create an appointment
     * @param requestType - HTTP request type. Either 'POST' when creating a new appointment or 'PUT' when updating an existing appointment
     * @param data - Appointment data to update. Can be a single appointment or an array of appointments
     * @param onSuccess - Callback function to execute on success
     * @param onError - Callback function to execute on error
     */
    const updateAppointment = useCallback(async (
        requestType: string,
        data: AppointmentItem | AppointmentItem[],
        onSuccess?: (response: AxiosResponse) => void,
        onError?: (error: any) => void
    ) => {
        if (!data || (Array.isArray(data) && data.length === 0)) {
            console.error('Invalid data passed to updateAppointment');
            return;
        }

        let requestData: string;
        if (Array.isArray(data)) {
            requestData = JSON.stringify(data);
        } else {
            requestData = JSON.stringify([data]);
        }

        try {
            const response = await sendRequest('/appointments', requestType, requestData, navigate)

            if (response === null) {
                console.error('Error updating appointment: ', response);
                return;
            }

            if (onSuccess) onSuccess(response);
            return true;
        } catch (error: any) {
            console.error('Error updating appointment', error.response || error);
            if (onError) onError(error);
            return false;
        }
    }, [navigate]);

    return (
        <DataContext.Provider value={{currentUser, fetchAppointments, updateAppointment, fetchUser}}>
            {children}
        </DataContext.Provider>
    );
};

export const useData = (): DataContextType => {
    const context = useContext(DataContext);
    if (context === undefined) {
        throw new Error('useData must be used within a DataProvider');
    }
    return context;
};
