import React, {useCallback, useEffect, useRef, useState} from "react";
import "./Admin.css";
import DatePicker from "react-multi-date-picker";
import {Navigate, useNavigate} from "react-router-dom";
import Logout from "../components/logout/Logout";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import {useData} from "../components/helper/DataContext";
import Sidebar from "../components/sidebar/Sidebar";
import {TextField} from "@mui/material";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import dayjs from "dayjs";
import isBetween from 'dayjs/plugin/isBetween';

dayjs.extend(isBetween);

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 375,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

class ButtonObj {
    constructor(start, end, active, title) {
        this.start = start;
        this.end = end;
        this.title = title;
        this.active = active;
    }
}

class DayColumn {
    constructor(childButtons) {
        this.childButtons = childButtons;
    }
}

class BatchObj {
    constructor(startTime, endTime, title) {
        this.startTime = startTime;
        this.endTime = endTime;
        this.title = title;
    }
}

// Bad way of forcing a re-render, only happens when toggeling appointments for deletion
export function useForceUpdate() {
    const [, setTick] = useState(0);

    const update = useCallback(() => {
        setTick(tick => tick + 1);
    }, [])

    return update;
}

function Generate({newData}) {
    const dayjsData = [];

    for (var i = 0; i < newData.dates.length; i++) {
        dayjsData.push(dayjs(newData.dates[i]));
    }

    newData.dates = dayjsData;

    return (
        <RenderGeneratedPreview data={newData}/>
    )
}

function pushSessionToCalender(days, sessionLength, intervalTo, intervalFrom, data, dayColumns) {
    for (let k = 0; k < days; k++) {
        const sessionsPerHour = parseFloat(sessionLength) / parseFloat(60);
        const timeAmount = parseFloat((intervalTo - intervalFrom) / sessionsPerHour);

        let start = data.dates[k].set('hour', intervalFrom).set('minute', 0).set('second', 0).subtract(sessionLength, 'minutes');
        let end = data.dates[k].set('hour', intervalFrom).set('minute', 0).set('second', 0);
        let title = data.title;

        let buttonObj = [];

        for (let i = 0; i < timeAmount; i++) {
            start = start.add(sessionLength, 'minute');
            end = end.add(sessionLength, 'minute');
            buttonObj.push(new ButtonObj(start, end, true, title));
        }

        dayColumns.push(new DayColumn(buttonObj));
    }
}

function GenerateTimeArray(sessionLength, intervalFrom, intervalTo, days, data) {
    const dayColumns = [];
    pushSessionToCalender(days, sessionLength, intervalTo, intervalFrom, data, dayColumns);
    return dayColumns;
}

function ConvertToMonth(monthIndex) {
    const monthNames = ["January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    return monthNames[monthIndex];
}

function ConvertToWeekDay(dayIndex) {
    const dayNames = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat",
        "Sun"
    ];

    return dayNames[dayIndex];
}

const handleActivate = (i, k, timeList, setTimeList, forceUpdate) => {
    setTimeList(prewArray => {
        prewArray[i].childButtons[k].active = !prewArray[i].childButtons[k].active;
        return prewArray;
    })

    forceUpdate();
}

const waitForResponse = async (arr, updateAppointment) => {
    const onSuccess = (response) => {
        if (response.status === 200) {
            console.log("Appointments created successfully");
        }
    };

    const onError = (error) => {
        console.error("Error creating appointments:", error);
    };

    try {
        return await updateAppointment('post', arr, onSuccess, onError);
    } catch (error) {
        console.error('Unexpected error:', error);
    }
};

const checkIfExist = async (batchObj, appointments) => {
    try {
        if (!appointments) {
            return false;
        }

        let returnValue = false;

        appointments.forEach((item) => {
            let obj = new BatchObj(dayjs(item.startTime), dayjs(item.endTime));

            if (item.state === "DELETED") {
                return;
            }

            if (
                batchObj.startTime.isBetween(obj.startTime, obj.endTime) ||
                batchObj.endTime.isBetween(obj.startTime, obj.endTime) ||
                batchObj.startTime.isSame(obj.startTime)
            ) {
                returnValue = true;
            }
        });

        return returnValue;

    } catch (error) {
        console.error('Error in checkIfExist:', error);
        return false;
    }
};

async function SendBatchObjs(timeList, setDis, data, fetchAppointments, forceUpdate, navigate, updateAppointment) {
    const arr = [];
    setDis(false);
    let todaysDate = new Date();
    let from = todaysDate.toISOString();
    const appointments = await fetchAppointments(from);
    forceUpdate();

    for (var i = 0; i < timeList.length; i++) {
        for (var k = 0; k < timeList[i].childButtons.length; k++) {
            if (timeList[i].childButtons[k].active) {
                let f_start = timeList[i].childButtons[k].start.toDate().toISOString();
                let f_end = timeList[i].childButtons[k].end.toDate().toISOString();
                let title = data.title;
                let batchObj = new BatchObj(new dayjs(f_start), new dayjs(f_end), title);

                const exists = await checkIfExist(batchObj, appointments);
                if (!exists) {
                    arr.push(batchObj);
                }
            }
        }
    }

    const received = await waitForResponse(arr, updateAppointment);

    if (received) {
        setDis(true);
        navigate("/booking");
    }
}

function renderButtons(dayColumn, i, handleActivate, dis, timeList, setTimeList, forceUpdate) {
    return (
        <div className='generate_column' key={i}>
            <label className='generate_label'>
                {dayColumn.childButtons[0].start.get('date')} {ConvertToMonth(dayColumn.childButtons[0].start.get('month'))}
            </label>
            <br/>
            <label className='generate_label'>
                {ConvertToWeekDay(dayColumn.childButtons[0].start.get('day'))}
            </label>
            {dayColumn.childButtons.map((buttonObj, k) => (
                <div onClick={() => handleActivate(i, k, timeList, setTimeList, forceUpdate)} disabled={!dis} key={k}>
                    <div
                        className={timeList[i].childButtons[k].active ? 'appointment_preview' : 'appointment_preview appointment_preview_disabled'}
                    >
                        <div className="tag"></div>
                        <div className="appointmentContent">
                            <div
                                className="time">{buttonObj.start.format("HH:mm")}-{buttonObj.end.format("HH:mm")}</div>
                            <div className="title">{buttonObj.title}</div>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

function RenderGeneratedPreview({data}) {
    data.dates.sort((a, b) => (dayjs(a).isAfter(dayjs(b)) ? 1 : -1))

    const INITIAL_LIST = GenerateTimeArray(parseFloat(data.sessionLength), parseFloat(data.intervalFrom), parseFloat(data.intervalTo), parseFloat(data.dates.length), data);
    const [timeList, setTimeList] = useState(INITIAL_LIST);
    const forceUpdate = useForceUpdate();
    const navigate = useNavigate();
    const [dis, setDis] = useState(true);
    const {updateAppointment, fetchAppointments} = useData();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const confirmButtonRef = useRef(null);
    const publishButtonRef = useRef(null);

    const handleModalToggle = () => {
        setModalIsOpen(!modalIsOpen);
    }

    const handleConfirmClick = () => {
        handleModalToggle();
        SendBatchObjs(timeList, setDis, data, fetchAppointments, forceUpdate, navigate, updateAppointment);
    };

    useEffect(() => {
        const listener = event => {
            if ((event.code === "Enter" || event.code === "NumpadEnter")) {
                event.preventDefault();
                if (modalIsOpen) {
                    confirmButtonRef.current.click();
                } else {
                    publishButtonRef.current.click();
                }
            }
        };

        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [modalIsOpen]);


    let appointmentAmount = 0;
    timeList.map((dayColumn, i) => {
        dayColumn.childButtons.map((buttonObj, k) => {
            if (buttonObj.active) {
                appointmentAmount++;
            }
        });
    });

    return (
        <>
            <div className="page">
                <div className="page6">
                    <div className='generate'>
                        <h2 className='admin-header-page'>
                            Publish bookings
                        </h2>
                        <h3 className="admin-description">(Click on a booking to remove it)</h3>
                        <h3 className='generate_year'>
                            {timeList[0].childButtons[0].start.get('year')}
                        </h3>
                        <div className='generate_grid_container'>
                            {timeList.map((dayColumn, i) => renderButtons(dayColumn, i, handleActivate, dis, timeList, setTimeList, forceUpdate))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="pageNext">
                <button className="admin-submit" value="cancel" onClick={() => navigate("/booking")}>CANCEL</button>
                <input ref={publishButtonRef} className="admin-submit" type="submit"
                       value={"PUBLISH " + appointmentAmount}
                       onClick={handleModalToggle}/>
            </div>
            <Modal open={modalIsOpen} onClose={handleModalToggle} aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                <Box sx={modalStyle} className='confirm-box'>
                    <QuestionMarkIcon className='confirm-icon'/>
                    <Typography id="modal-modal-description" sx={{mt: 2}} className='confirm-label'>
                        Are you sure you want to create these bookings?
                    </Typography>
                    <Button className='cancel-button' variant="contained" onClick={handleModalToggle}>
                        Cancel
                    </Button>
                    <Button ref={confirmButtonRef} className='confirm-button' variant="contained"
                            onClick={handleConfirmClick}>
                        Confirm
                    </Button>
                </Box>
            </Modal>
        </>
    );
}

const FirstStep = ({value, handleCalendarChange, handleTitleChange, cancelButton, nextStep, canProceedStep1}) => (
    <>
        <h2>Step 1:</h2>
        <div className="stepper-background">
            <div className="stepper">
                <span className="dot" style={{backgroundColor: '#EC0000'}}></span>
                <span className="lineBetween"></span>
                <span className="dot"></span>
                <span className="lineBetween"></span>
                <span className="dot"></span>
            </div>
        </div>
        <div className="page">
            <div className="page3">
                <h2 className="admin-header-page">DATE</h2>
                <h3 className="admin-description">Choose one or more dates for your booking<span
                    className="required">*</span></h3>
                <DatePicker name="dates" value={value.dates} onChange={handleCalendarChange} multiple={true}
                            minDate={new Date()}/>
            </div>
            <div className="page1">
                <h2 className="admin-header-page">TITLE</h2>
                <h3 className="admin-description">Add a title to your bookings! <span
                    className="optional">(Optional)</span></h3>
                <TextField
                    onChange={handleTitleChange}
                    label="Max 14 characters"
                    variant="outlined"
                    onInput={(e) => {
                        e.target.value = e.target.value.toString().slice(0, 14);
                    }}
                />
            </div>
        </div>
        <div className="pageNext">
            <button className="admin-submit" value="CANCEL" onClick={cancelButton}>CANCEL</button>
            <button className="admin-submit" value="NEXT" id="firstPage" onClick={nextStep}
                    disabled={!canProceedStep1}>NEXT
            </button>
        </div>
    </>
);

const SecondStep = ({
                        value,
                        handleChange,
                        sessionOptions,
                        fromIntervalOptions,
                        toIntervalOptions,
                        prevStep,
                        nextStep
                    }) => (
    <>
        <h2>Step 2:</h2>
        <div className="stepper-background">
            <div className="stepper">
                <span className="dot"></span>
                <span className="lineBetween"></span>
                <span className="dot" style={{backgroundColor: '#EC0000'}}></span>
                <span className="lineBetween"></span>
                <span className="dot"></span>
            </div>
        </div>
        <div className="page">
            <div className="page4">
                <h2 className="admin-header-page">SESSION LENGTH</h2>
                <h3 className="admin-description">Decide the length of your bookings</h3>
                <label className="admin-label">
                    <select className="admin-select" name="sessionLength" value={value.sessionLength}
                            onChange={handleChange}>
                        {sessionOptions}
                    </select>
                    minutes
                </label>
            </div>
            <div className="page5">
                <h2 className="admin-header-page">TIME INTERVAL</h2>
                <h3 className="admin-description">Choose a time interval</h3>
                <label className="admin-label">
                    From:
                    <select className="admin-select" name="intervalFrom" value={value.intervalFrom}
                            onChange={handleChange}>
                        {fromIntervalOptions}
                    </select>
                    To:
                    <select className="admin-select" name="intervalTo" value={value.intervalTo} onChange={handleChange}>
                        {toIntervalOptions}
                    </select>
                </label>
            </div>
            <span className="optional">If you choose a time in the past the booking will not show up!</span>
        </div>
        <div className="pageNext">
            <button className="admin-submit" value="BACK" onClick={prevStep}>BACK</button>
            <button className="admin-submit" value="NEXT" id="secondPage" onClick={nextStep}>NEXT</button>
        </div>
    </>
);

const FinalStep = ({value}) => (
    <>
        <h2>FINAL STEP:</h2>
        <div className="stepper-background">
            <div className="stepper">
                <span className="dot"></span>
                <span className="lineBetween"></span>
                <span className="dot"></span>
                <span className="lineBetween"></span>
                <span className="dot" style={{backgroundColor: '#EC0000'}}></span>
            </div>
        </div>
        <Generate newData={value}/>
    </>
);

function Form() {
    const sessionList = [null, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60]; // new appointments lengths in minutes
    const intervalList = [null, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]; // new appointments available start and end times in hours
    const [canProceed, setCanProceed] = useState(false);
    const [step, setStep] = useState(1);
    const [value, setValue] = useState({
        sessionLength: sessionList[6],
        intervalFrom: intervalList[1],
        intervalTo: intervalList[2],
        dates: [],
        title: "",
    });

    const sessionOptions = sessionList.map(item => (
        <option key={item} value={item}>{item}</option>
    ));

    const fromIntervalOptions = intervalList.map((item, index) => (
        <option key={index} value={item}>
            {item}
        </option>
    ));

    const toIntervalOptions = intervalList.map((item, index) => (
        <option key={index} value={item}>
            {item}
        </option>
    ));

    const handleTitleChange = event => {
        const newTitle = event.target.value;
        setValue(prevValue => ({...prevValue, title: newTitle}));
    };

    const handleChange = event => {
        const newValue = event.target.value;
        //Convert new value to integer
        let newValueInt = parseInt(newValue);

        setValue(prevValue => ({...prevValue, [event.target.name]: newValue}));

        if (event.target.name === "intervalFrom") {
            //If intervalTo is less than intervalFrom, set intervalTo to intervalFrom
            if (value.intervalTo <= newValueInt) {
                newValueInt++;
                setValue(prevValue => ({...prevValue, intervalTo: newValueInt}));
            }
        }

        if (event.target.name === "intervalTo") {
            //If intervalFrom is greater than intervalTo, set intervalFrom to intervalTo
            if (value.intervalFrom >= newValueInt) {
                newValueInt--;
                setValue(prevValue => ({...prevValue, intervalFrom: newValueInt}));
        }
            }

        console.log(event.target.name)

    };

    const handleCalendarChange = event => {
        setValue(prevValue => ({...prevValue, dates: event}));
    };

    const handleSubmit = event => {
        event.preventDefault();
    };

    const nextStep = () => setStep(prevStep => prevStep + 1);
    const prevStep = () => setStep(prevStep => prevStep - 1);
    const cancelButton = () => window.location = '/booking';


    useEffect(() => {
        setCanProceed(value.intervalFrom != null && value.intervalTo != null && value.sessionLength != null && value.dates.length > 0);
    }, [value.intervalFrom, value.intervalTo, value.sessionLength, value.dates]);

    useEffect(() => {
        const listener = event => {
            if ((event.code === "Enter" || event.code === "NumpadEnter")) {
                event.preventDefault();
                if (step === 1 && canProceed) {
                    setStep(prevStep => prevStep + 1);
                } else if (step > 1 && step <= 2) {
                    setStep(prevStep => prevStep + 1);
                }
            }
        };
        document.addEventListener("keydown", listener);
        return () => {
            document.removeEventListener("keydown", listener);
        };
    }, [step, canProceed]);

    const getPage = step => {
        switch (step) {
            case 1:
                return (
                    <FirstStep
                        value={value}
                        handleCalendarChange={handleCalendarChange}
                        handleTitleChange={handleTitleChange}
                        cancelButton={cancelButton}
                        nextStep={nextStep}
                        canProceedStep1={canProceed}
                    />
                );
            case 2:
                return (
                    <SecondStep
                        value={value}
                        handleChange={handleChange}
                        sessionOptions={sessionOptions}
                        fromIntervalOptions={fromIntervalOptions}
                        toIntervalOptions={toIntervalOptions}
                        prevStep={prevStep}
                        nextStep={nextStep}
                    />
                );
            case 3:
                return <FinalStep value={value}/>;
            default:
                return null;
        }
    };

    return (
        <div className="admin">
            <h1 className="admin-header">CREATE BOOKINGS</h1>
            <form onSubmit={handleSubmit}>
                {getPage(step)}
            </form>
        </div>
    );
}

const Admin = () => {

    const {currentUser} = useData();

    //  Save current users role
    const role = currentUser.role;

    const oktaTokenUndefined = localStorage.getItem("okta-token-storage") === null || localStorage.getItem("okta-token-storage") === "{}" || JSON.parse(localStorage.getItem("okta-token-storage")).idToken === undefined;

    if (oktaTokenUndefined) {
        console.log("Token is undefined in admin page")
        return (
            <>
                <Navigate to="/" replace={true}/>
            </>
        );
    }

    if (role === "USER") {
        return (
            <>
                <Navigate to="/booking" replace={true}/>
            </>
        );
    }

    if (role === "ADMIN") {
        return (
            <div className="App">
                <Logout/>
                <Sidebar/>
                <Form/>
            </div>
        );
    } else {
        return (
            <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "100vh"}}>
                <CircularProgress/>
            </Box>
        );
    }
};

export default Admin;