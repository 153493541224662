import React from "react";
import { useNavigate } from "react-router-dom";
import { useData } from "../helper/DataContext";
import "./sidebar.css";
import logo from "./images/Ballys-red.png";
import { adminButtons, navigationButtons } from './SidebarData';


const Sidebar: React.FC = () => {
    const navigate = useNavigate();
    const { currentUser } = useData();

    // Fetches user role
    const role : string = currentUser?.role;

    // Check for specific idToken, if it checks out, grant admin access (aka show admin features)
    const checkAdminAccess = () => {
        if (role === "ADMIN") {
            return (
                <>
                    {navigationButtons.map((item: NavigateButton, key: number) => (
                        <div
                            className="sidebar-item"
                            key={key}
                            onClick={() => navigate(item.path)}
                        >
                            {item.title}
                        </div>
                    ))}
                    <div className="dropdown">
                        <div className="sidebar-item dropdown-title" onClick={() => navigate("/admin")}>
                            ADMIN
                        </div>
                        <div className="dropdown-content">
                            {adminButtons.map((item: NavigateButton, key: number) => (
                                <div
                                    className="dropdown-item"
                                    key={key}
                                    onClick={() => navigate(item.path)}
                                >
                                    {item.title}
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            );
        }
    };


    return (
        <div className="sidebar-container">
            <img className="navbar-logo" src={logo} alt="Bally's logo" />
            <div className="sidebar-item-container">
                {checkAdminAccess()}
            </div>
        </div>
    );
};

export default Sidebar;
