import React from "react";
import {format,} from "date-fns";
import "./Calendar.css";
import {ArrowBackIos, ArrowForwardIos,} from "@mui/icons-material";
import FormatListBulletedSharpIcon from "@mui/icons-material/FormatListBulletedSharp";
import CalendarTodaySharpIcon from "@mui/icons-material/CalendarTodaySharp";
import {Switch, Tooltip} from "@mui/material";

interface CalenderButtonsProps {
    weekView: boolean;
    currentWeek: number;
    startDate: Date;
    endDate: Date;
    changeWeekHandle: (change: number) => void;
    activeButton: string;
    onWeekViewClickHandle: () => void;
    onListViewClickHandle: () => void;
    setFilterAppointments: (flag: boolean) => void;
}

const CalenderButtons: React.FC<CalenderButtonsProps> = ({
                                                             weekView,
                                                             currentWeek,
                                                             startDate,
                                                             endDate,
                                                             changeWeekHandle,
                                                             activeButton,
                                                             onWeekViewClickHandle,
                                                             onListViewClickHandle,
                                                             setFilterAppointments,
                                                         }) => {
    const dateFormat = "MMM yyyy";

    const handleSwitchChange = (_event: React.ChangeEvent, checked: boolean) => {
        setFilterAppointments(checked);
    }

    return (
        <div className="week-header row">
            <div className="row row-middle" style={{visibility: weekView ? "visible" : "hidden"}}>
                <div className="row">
                    <div className="col col-end">
                        <div className="icon" onClick={() => changeWeekHandle(-1)}>
                            <ArrowBackIos className="arrow"/>
                        </div>
                    </div>
                    <div className="col col-center current-week">
                        <div>Week {currentWeek}</div>
                        <span>
              {startDate.getDate()} {format(startDate, dateFormat)} - {" "}
                            {endDate.getDate()}{" "}
                            {format(endDate, dateFormat)}
            </span>
                    </div>
                    <div className="col col-start">
                        <div className="icon" onClick={() => changeWeekHandle(1)}>
                            <ArrowForwardIos className="arrow"/>
                        </div>
                    </div>
                </div>
            </div>
            <div className="buttons-container">
                <div
                    className={`default-button regular-button ${activeButton === "week" ? "selected-button" : ""}`}
                    onClick={onWeekViewClickHandle}
                >
                    <CalendarTodaySharpIcon/>
                    <div>Calendar</div>
                </div>
                <div
                    className={`default-button regular-button ${activeButton === "list" ? "selected-button" : ""}`}
                    onClick={onListViewClickHandle}
                >
                    <FormatListBulletedSharpIcon/>
                    <div>List</div>
                </div>
                <Tooltip title="Filter by available appointments">
                    <div className="default-button filter-button">
                        <Switch onChange={handleSwitchChange}/>
                        <p>HIDE BOOKED</p>

                    </div>
                </Tooltip>
            </div>
        </div>
    );
};

export default CalenderButtons;
