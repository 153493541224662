import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { getFormattedStartTime, getFormattedExpiredTime } from "../helper/Utils";

interface TradeRequestListProps {
    info: TradeRequestData[];
    receiving: boolean;
    isOpen: boolean;
    currentUser: User;
    onAccept: (trade: TradeRequestData) => void;
    onDecline: (trade: TradeRequestData) => void;
    onCancel: (trade: TradeRequestData) => void;
}

const TradeRequestList: React.FC<TradeRequestListProps> = ({
                                                               info,
                                                               receiving,
                                                               isOpen,
                                                               currentUser,
                                                               onAccept,
                                                               onDecline,
                                                               onCancel
                                                           }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const requestsPerPage = 5;

    const filteredInfo = receiving
        ? info.filter((request) => request.requestedAppointment.oktaSub === currentUser.oktaSub)
        : info.filter((request) => request.requestedAppointment.oktaSub !== currentUser.oktaSub);

    const indexOfLastRequest = currentPage * requestsPerPage;
    const indexOfFirstRequest = indexOfLastRequest - requestsPerPage;
    const currentRequests = filteredInfo.slice(indexOfFirstRequest, indexOfLastRequest);

    const handleNextPage = () => {
        if (currentPage < Math.ceil(filteredInfo.length / requestsPerPage)) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const renderRequestInfo = (data: TradeRequestData) => (
        <Box className='trade-request-content'>
            <Typography>
                Requested: {data.requestedAppointment.title ? `${data.requestedAppointment.title} on ` : ''} {getFormattedStartTime(data.requestedAppointment)}
            </Typography>
            <Typography>
                Offered: {data.offeredAppointment ? (data.offeredAppointment.title ? `${data.offeredAppointment.title} on ` : '') + getFormattedStartTime(data.offeredAppointment) : 'none'}
            </Typography>
            <Typography>{data.otherUser.name}</Typography>
            <Typography>
                Expires by {data.expiredTime  ? getFormattedExpiredTime(data.expiredTime.toString()) : 'No expiry date'}
            </Typography>
            <Box className='trade-request-message'>{data.message}</Box>
        </Box>
    );

    const renderRequest = (data: TradeRequestData) => (
        <Box className='trade-request-box' key={data.id}>
            {renderRequestInfo(data)}
            {isOpen ? (
                <Box className='trade-request-actions'>
                    <Tooltip title="Accept">
                        <IconButton className='accept-btn' onClick={() => onAccept(data)} aria-label="Accept"><CheckIcon /></IconButton>
                    </Tooltip>
                    <Tooltip title="Decline">
                        <IconButton className='decline-btn' onClick={() => onDecline(data)} aria-label="Decline"><CloseIcon /></IconButton>
                    </Tooltip>
                </Box>
            ) : (
                data.state
            )}
        </Box>
    );

    const renderSendingRequest = (data: TradeRequestData) => (
        <Box className='trade-request-box' key={data.id}>
            {renderRequestInfo(data)}
            {isOpen ? (
                <Box className='trade-request-actions'>
                    <Tooltip title="Cancel the request">
                        <IconButton className='decline-btn' onClick={() => onCancel(data)} aria-label="Cancel"><CloseIcon /></IconButton>
                    </Tooltip>
                </Box>
            ) : (
                data.state
            )}
        </Box>
    );

    return (
        <div>
            {currentRequests.map((request) => receiving ? renderRequest(request) : renderSendingRequest(request))}
            {filteredInfo.length > requestsPerPage && (
                <Box display="flex" justifyContent="space-between" mt={2}>
                    <IconButton onClick={handlePrevPage} disabled={currentPage === 1} aria-label="Previous">
                        <ArrowBackIcon />
                    </IconButton>
                    <IconButton onClick={handleNextPage} disabled={currentPage >= Math.ceil(filteredInfo.length / requestsPerPage)} aria-label="Next">
                        <ArrowForwardIcon />
                    </IconButton>
                </Box>
            )}
        </div>
    );
};

export default TradeRequestList;
