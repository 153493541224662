import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { sendRequest, useData } from "../helper/DataContext";
import { useNavigate } from "react-router-dom";
import TradeRequestList from './TradeRequestList';
import './tradeRequest.css';
import { getFormattedStartTime } from "../helper/Utils";

const TradeRequest: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [logView, setLogView] = useState(false);
    const { currentUser } = useData();
    const [tradeRequests, setTradeRequests] = useState<TradeRequestData[]>([]);
    const [tradeReqHistory, setTradeReqHistory] = useState<TradeRequestData[]>([]);
    const [modalQueue, setModalQueue] = useState<TradeRequestData[]>([]);
    const [currentTrade, setCurrentTrade] = useState<TradeRequestData | null>(null);
    const [isRequester, setIsRequester] = useState<boolean | null>(null);

    const navigate = useNavigate();

    const handleToggle = () => {
        setOpen(!open);
        setLogView(false);
    };

    const handleLogToggle = () => {
        setLogView(!logView);
    };

    const handleCloseModal = async () => {
        if (currentTrade) {
            const updatedTrade = {
                ...currentTrade,
                seenRequested: true,
            };

            try {
                const response = await sendRequest('/trades/seen', 'put', JSON.stringify(updatedTrade), navigate);

                if (response.status === 200) {
                    if (modalQueue.length > 0) {
                        setCurrentTrade(modalQueue[1]);
                        setModalQueue(modalQueue.slice(1));
                    } else {
                        setCurrentTrade(null);
                        setIsRequester(null);
                    }
                }
            } catch (error) {
                console.error('Error marking trade as seen: ', error);
                setCurrentTrade(null);
                setIsRequester(null);
            }
        }
    };

    const checkAndQueueModals = (trades: TradeRequestData[]) => {
        const unseenTrades = trades.filter(trade => {
            const isRequester = currentUser.oktaSub === trade.requesterUser.oktaSub;
            return (isRequester && (trade.state === 'ACCEPTED' || trade.state === 'DECLINED') && !trade.seenRequested);
        });

        if (unseenTrades.length > 0) {
            setModalQueue(unseenTrades);
            setCurrentTrade(unseenTrades[0]);
        }
    };

    useEffect(() => {
        const updateTradeRequests = async () => {
            try {
                const response = await sendRequest('/trades/user', 'get', null, navigate);

                const reversedData = response.data.reverse();

                setTradeRequests(reversedData.filter((request: TradeRequestData) => request.state === 'PENDING'));
                setTradeReqHistory(reversedData.filter((request: TradeRequestData) => request.state !== 'PENDING'));

                checkAndQueueModals(reversedData);
            } catch (error) {
                console.error('Error fetching trade requests: ', error);
            }
        }
        updateTradeRequests();
    }, [open]);

    const acceptTradeRequest = async (trade: TradeRequestData) => {
        console.log(`Accepted request: ${trade}`);
        try {
            const response = await sendRequest('/trades/accept', 'put', JSON.stringify(trade), navigate);

            if (response.status === 200) {
                setTradeRequests(tradeRequests.filter((request: TradeRequestData) => request.id !== trade.id));
                trade.state = 'ACCEPTED';
                setTradeReqHistory([...tradeReqHistory, trade]);
            }
        } catch (error) {
            console.error('Error accepting trade request: ', error);
        }
    };

    const declineTradeRequest = async (trade: TradeRequestData) => {
        console.log(`Declined request: ${trade}`);
        try {
            let response = await sendRequest('/trades/decline', 'put', JSON.stringify(trade), navigate);

            if (response.status === 200) {
                setTradeRequests(tradeRequests.filter((request: TradeRequestData) => request.id !== trade.id));
                trade.state = 'DECLINED';
                setTradeReqHistory([...tradeReqHistory, trade]);
            }
        } catch (error) {
            console.error('Error declining trade request: ', error);
        }
    };

    const cancelTradeRequest = async (trade: TradeRequestData) => {
        console.log(`Canceled request: ${trade}`);
        try {
            let response = await sendRequest('/trades/delete', 'put', JSON.stringify(trade), navigate);

            if (response.status === 200) {
                setTradeRequests(tradeRequests.filter((request: TradeRequestData) => request.id !== trade.id));
            }
        } catch (error) {
            console.error('Error canceling trade request: ', error);
        }
    };

    return (
        <div className='trade-request-container'>
            <Button onClick={handleToggle} className='trade-request-btn'>
                <p className="trade-req-text">Trade Requests</p>
                {tradeRequests.length > 0 && (
                    <Box className='trade-request-count'>
                        {tradeRequests.length}
                    </Box>
                )}
            </Button>
            <Collapse in={open}>
                <div className='trade-request-table'>
                    <div className='trade-request-section'>

                            <Button onClick={handleLogToggle} className='log-btn'>{logView ? "Show Active" : "Show History"}</Button>
                            <h3>{logView ? "SENT" : "SENDING"}</h3>
                        {logView ? (
                            <TradeRequestList
                                info={tradeReqHistory}
                                receiving={false}
                                isOpen={!logView}
                                currentUser={currentUser}
                                onAccept={acceptTradeRequest}
                                onDecline={declineTradeRequest}
                                onCancel={cancelTradeRequest}
                            />
                        ) : (
                            <TradeRequestList
                                info={tradeRequests}
                                receiving={false}
                                isOpen={!logView}
                                currentUser={currentUser}
                                onAccept={acceptTradeRequest}
                                onDecline={declineTradeRequest}
                                onCancel={cancelTradeRequest}
                            />
                        )}
                    </div>
                    <div className='trade-request-section'>
                        <h3>{logView ? "RECEIVED" : "RECEIVING"}</h3>
                        {logView ? (
                            <TradeRequestList
                                info={tradeReqHistory}
                                receiving={true}
                                isOpen={!logView}
                                currentUser={currentUser}
                                onAccept={acceptTradeRequest}
                                onDecline={declineTradeRequest}
                                onCancel={cancelTradeRequest}
                            />
                        ) : (
                            <TradeRequestList
                                info={tradeRequests}
                                receiving={true}
                                isOpen={!logView}
                                currentUser={currentUser}
                                onAccept={acceptTradeRequest}
                                onDecline={declineTradeRequest}
                                onCancel={cancelTradeRequest}
                            />
                        )}
                    </div>
                </div>
            </Collapse>
            <Modal
                open={!!currentTrade}
                onClose={handleCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className='trade-notification' sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 3 }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Trade Notification
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                        {currentTrade && (
                            currentTrade.state === "ACCEPTED"
                                ? `${currentTrade.otherUser.name} ${currentTrade.state.toLowerCase()} your trade. 
                                Your new appointment starts at ${getFormattedStartTime(currentTrade.requestedAppointment)}. `
                                : `${currentTrade.otherUser.name} ${currentTrade.state.toLowerCase()} your trade. 
                                You tried to request the appointment starting at ${getFormattedStartTime(currentTrade.requestedAppointment)}. `
                        )}
                         Check out your trade history for more information.
                    </Typography>
                    <Button variant="contained"  onClick={handleCloseModal} sx={{ mt: 2 }}>Close</Button>
                </Box>
            </Modal>
        </div>
    );
};

export default TradeRequest;
