import React, {useState, useEffect} from 'react';
import {useData} from '../../helper/DataContext';
import {Box, List, ListItem, ListItemButton, ListItemText, Typography, FormControl, MenuItem, Select, SelectChangeEvent, InputLabel, TextField} from '@mui/material';
import {getFormattedStartTime} from "../../helper/Utils";
import dayjs from "dayjs";

interface TradeRequestInfoProps {
    allAppointment: AppointmentItem[];
    selectedAppointment: AppointmentItem | null;
    setSelectedAppointment: (appointment: AppointmentItem | null) => void;
    setText: (text: string) => void;
    setExpiredTime: (expiredTime: string) => void;
}

const TradeRequestInfo: React.FC<TradeRequestInfoProps> = ({allAppointment, selectedAppointment, setSelectedAppointment, setText, setExpiredTime}) => {
    const {currentUser} = useData() as { currentUser: User };
    const [inputValue, setInputValue] = useState("");

    const ownAppointments = allAppointment.filter(appointment => appointment.oktaSub === currentUser.oktaSub);
    if (ownAppointments.length > 0 && !selectedAppointment) {
        setSelectedAppointment(ownAppointments[0]);
    }

    const handleSelectAppointment = (appointment: AppointmentItem) => {
        setSelectedAppointment(appointment);
        console.log(`Appointment selected: ${appointment.startTime}`);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value;
        if (value.length <= 50) {
            setInputValue(value);
            setText(value);
        }
    };

    const handleSelectChange = (event: { target: { value: any; }; }) => {
        const hours = event.target.value;
        updateExpirationTime(hours);
    };

    const updateExpirationTime = (hours: number) => {
        if (hours === 0) {
            setExpiredTime("");
        } else {
            const expirationDate = dayjs().add(hours, 'hour').toISOString();
            setExpiredTime(expirationDate);
        }
    };

    useEffect(() => {
        updateExpirationTime(24);
        setInputValue("");
        setText("");
    }, []);

    return (
        <Box sx={{width: '100%', bgcolor: 'background.paper'}}>
            {ownAppointments.length > 0 ? (
                <div>
                    <br/>

                    <p>Offering Appointment:</p>
                    <List>
                        {ownAppointments.map((appointment, index) => (
                            <ListItem key={index} disablePadding>
                                <ListItemButton
                                    selected={selectedAppointment?.startTime === appointment.startTime}
                                    onClick={() => handleSelectAppointment(appointment)}
                                >
                                    <ListItemText primary={getFormattedStartTime(appointment)}/>
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </div>
            ) : (
                <Typography variant="body1" color="textPrimary">
                    You have no current appointments. This means you will send a request to get the time without
                    offering anything.
                </Typography>
            )}
            <FormControl fullWidth margin="normal">
                <InputLabel id="expiration-time-label">Expiration Time</InputLabel>
                <Select
                    defaultValue={24}
                    label="Expiration Time"
                    onChange={handleSelectChange}
                >
                    <MenuItem value={0}>NO LIMIT</MenuItem>
                    <MenuItem value={1}>1 HOUR</MenuItem>
                    <MenuItem value={24}>1 DAY</MenuItem>
                    <MenuItem value={168}>1 WEEK</MenuItem>
                </Select>

            </FormControl>
            <TextField
                label="Optional message"
                value={inputValue}
                placeholder={"Optional message"}
                onChange={handleInputChange}
                inputProps={{ maxLength: 50 }}
                fullWidth
                margin="normal"
            />
        </Box>
    );
};

export default TradeRequestInfo;
