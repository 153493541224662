import React, {useState} from 'react';
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import QuestionMarkIcon from '@mui/icons-material/HelpOutline';

interface DeleteButtonProps {
    role: string;
    deleteAction: () => void;
    prevModal?: () => void; //Used to pass a close modal handler if a previous modal is open

}

const modalStyle = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};
const DeleteButton: React.FC<DeleteButtonProps> = ({role, deleteAction, prevModal}) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);

    if (role !== "ADMIN") return null;

    const handleModalToggle = () => {
        if (modalIsOpen && prevModal)
            prevModal();
        setModalIsOpen(!modalIsOpen);

    };

    const handleConfirmDelete = () => {
        handleModalToggle();
        deleteAction();
    };

    return (
        <>
            <Button variant="contained" className="btn-delete" onClick={handleModalToggle}>
                DELETE
            </Button>
            <Modal
                open={modalIsOpen}
                onClose={handleModalToggle}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={modalStyle} className='confirm-box'>
                    <QuestionMarkIcon className='confirm-icon'/>
                    <Typography id="modal-modal-description" sx={{mt: 2}} className='confirm-label'>
                        Are you sure you want to delete this item?
                    </Typography>
                    <Button className='cancel-button' variant="contained" onClick={handleModalToggle}>
                        Cancel
                    </Button>
                    <Button className='confirm-button' variant="contained" onClick={handleConfirmDelete}>
                        Confirm
                    </Button>
                </Box>
            </Modal>
        </>
    );
};

export default DeleteButton;
