import React from "react";
import { format } from "date-fns";
import "./Calendar.css";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";

import { useData } from "../helper/DataContext";

interface CalendarListProps {
    appointments: AppointmentItem[];
    onDateClickHandle: (appointment: AppointmentItem) => void;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
        textAlign: "center",
    },
}));

const StyledTableRow = styled(TableRow)(() => ({
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const CalendarList: React.FC<CalendarListProps> = ({
                                                       appointments,
                                                       onDateClickHandle,
                                                   }) => {
    const { currentUser } = useData();
    const oktaSub = currentUser.oktaSub;

    const renderList = () => {
        return (
            <TableContainer component={Paper} className="list-table">
                <Table>
                    <TableBody>
                        {appointments.map((appointment) => {
                            let tagColor;
                            // Determine the tag color based on state and user
                            if (appointment.state === "BOOKED") {
                                if (appointment.oktaSub === oktaSub) {
                                    tagColor = "#7ee57e"; // User's booking -> green
                                } else {
                                    tagColor = "#ff4a4a"; // Booked by someone else -> red
                                }
                            } else {
                                tagColor = "#1A8FFF"; // Default -> blue
                            }

                            // Format the start and end times
                            const startAppointment = new Date(appointment.startTime);
                            const endAppointment = new Date(appointment.endTime);
                            const formattedAppointment = format(startAppointment, "yyyy-MM-dd");
                            const formattedStartTime = format(startAppointment, "HH:mm");
                            const formattedEndTime = format(endAppointment, "HH:mm");

                            return (
                                <StyledTableRow
                                    key={appointment.id}
                                    onClick={() => {
                                        onDateClickHandle(appointment);
                                    }}
                                    className="list-row"
                                >
                                    <StyledTableCell>
                                        <div className="tag" style={{ backgroundColor: `${tagColor}` }}></div>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <span className="list-title">
                                            {appointment.title == null ? "No title" : appointment.title}
                                        </span>
                                    </StyledTableCell>
                                    <StyledTableCell>
                                        <span>
                                            <div>{formattedAppointment}</div>
                                            <div>
                                                {formattedStartTime} - {formattedEndTime}
                                            </div>
                                        </span>
                                    </StyledTableCell>
                                    <StyledTableCell>{appointment.name}</StyledTableCell>
                                    <StyledTableCell>
                                        {appointment.state === "BOOKED" ? (
                                            <div>
                                                {appointment.oktaSub === currentUser.oktaSub
                                                    ? "YOUR BOOKING"
                                                    : "BOOKED"}
                                            </div>
                                        ) : (
                                            <div>AVAILABLE</div>
                                        )}
                                    </StyledTableCell>
                                </StyledTableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        );
    };

    return renderList();
};

export default CalendarList;
