import { useEffect } from "react";
import { useData } from "./DataContext";

interface UseCalendarAppointmentsParams {
    navigate: (path: string) => void;
    from: string;
    setAllAppointment: (appointments: AppointmentItem[]) => void;
    tick: number;
}

function useCalendarAppointments({
                                     navigate,
                                     from,
                                     setAllAppointment,
                                     tick,
                                 }: UseCalendarAppointmentsParams) {
    const { fetchAppointments } = useData();

    const fetchAndSetAppointments = async () => {
        try {
            const localToken = localStorage.getItem("okta-token-storage");

            if (!localToken || localToken === "{}") {
                navigate("/");
                return;
            }

            const response = await fetchAppointments(from);
            const processedAppointments = processAppointments(response);
            setAllAppointment(processedAppointments);
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        fetchAndSetAppointments();
    }, [tick]);

    return { fetchAndSetAppointments };
}

// Helper function to process appointments
function processAppointments(appointments: AppointmentItem[]): AppointmentItem[] {
    const sortedData = appointments.sort(
        (a: AppointmentItem, b: AppointmentItem) =>
            Date.parse(a.startTime) - Date.parse(b.startTime)
    );

    return sortedData.map((item) => {
        const date = new Date(item.startTime);
        const time = `${date.getHours()}:${date.getMinutes() < 10 ? "0" : ""}${date.getMinutes()}`;

        return {
            id: item.id,
            title: item.title,
            oktaSub: item.oktaSub,
            time: time,
            startTime: item.startTime,
            endTime: item.endTime,
            state: item.state,
            name: item.name,
            selected: false,
            version: item.version,
        };
    });
}

export default useCalendarAppointments;

// Separate function that can be used independently
export async function fetchAppointments(
    fetchAppointmentsFunc: (from: string) => Promise<AppointmentItem[]>,
    navigate: (path: string) => void,
    from: string
): Promise<AppointmentItem[]> {
    const localToken = localStorage.getItem("okta-token-storage");

    if (!localToken || localToken === "{}") {
        navigate("/");
        return [];
    }

    try {
        const response = await fetchAppointmentsFunc(from);
        return processAppointments(response);
    } catch (error) {
        console.log(error);
        return [];
    }
}