import React from 'react';
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import { useOktaAuth } from '@okta/okta-react';
import './logout.css';

const Logout: React.FC = () => {
    const { oktaAuth } = useOktaAuth();

    /* Currently ending their session on the Okta authorization server and ending
    their local session to sign out of application */
    const logout = async () => {
        try {
            oktaAuth.tokenManager.clear();
            const signOut = await oktaAuth.signOut();
            console.log("result: ", signOut);
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <div className='logout-container'>
            <Button onClick={logout} className='logout-btn'>
                <LogoutIcon />
            </Button>
        </div>
    );
};

export default Logout;
