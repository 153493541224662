import React, {forwardRef, useRef, useState} from "react";
import {format} from 'date-fns';
import {styled} from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import {Navigate, useNavigate} from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Logout from "../components/logout/Logout";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import ReactToPrint from "react-to-print";
import Button from '@mui/material/Button';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import {useData} from "../components/helper/DataContext";
import {DateRangePicker} from "rsuite";
import 'rsuite/dist/rsuite.min.css';
import "./overview.css";
import Sidebar from "../components/sidebar/Sidebar";
import useDate, {OverviewState} from "../components/helper/useDate";
import {DateRange} from "rsuite/esm/DateRangePicker";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const LoadingView: React.FC = () => (
    <Box
        sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
        }}
    >
        <CircularProgress/>
    </Box>
);

const UserRedirect: React.FC = () => <Navigate to="/booking" replace={true}/>;

interface AdminViewProps {
    setOverviewFrom: React.Dispatch<React.SetStateAction<Date>>;
    setOverviewTo: React.Dispatch<React.SetStateAction<Date>>;
    todayEvening: number;

    filterOption: string;
    overview: OverviewState;
    dateRange: [Date, Date];
    setDateRange: React.Dispatch<React.SetStateAction<[Date, Date]>>;
}

const AdminView = forwardRef<HTMLTableElement | null, AdminViewProps>((props, ref) => {
    const {
        setOverviewFrom,
        setOverviewTo,
        todayEvening,
        filterOption,
        overview,
        dateRange,
        setDateRange
    } = props;

    const lookBackMonths = (months: number) => {
        let tempDate = new Date();
        tempDate.setMonth(tempDate.getMonth() - months);
        let fromDate = new Date(tempDate);
        fromDate.setDate(fromDate.getDate() - 1);
        setOverviewFrom(new Date(fromDate.setHours(8, 0, 0, 0)));
        let toDate = new Date(todayEvening);
        toDate.setDate(toDate.getDate() + 1); // One day after
        setOverviewTo(toDate);
        setDateRange([new Date(fromDate), new Date(toDate)]);
    };

    return (
        <div className="App">
            <div className='print-container'>
                <ReactToPrint
                    trigger={() => <Button className='print-button'>Print <PrintOutlinedIcon/> </Button>}
                    content={() => {
                        // @ts-ignore
                        return ref?.current;
                    }}
                />
            </div>
            <Sidebar/>
            <Logout/>
            <TableContainer component={Paper} className="overview-table">
                <Table sx={{minWidth: 700}} aria-label="customized table" ref={ref}>
                    <TableHead className='table-header'>
                        <TableRow>
                            <StyledTableCell align="center" className="table-header">
                                <h1>overview</h1>
                            </StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell align="center"
                                             className="overview-searching-header overview-quick-filter-buttons">
                                <span className="overview-quickfilter-container">
                                    <button onClick={() => lookBackMonths(1)}>Show Past Month</button>
                                    <button onClick={() => lookBackMonths(6)}>Show Past 6 Months</button>
                                </span>
                            </StyledTableCell>
                            <StyledTableCell align="center" className="overview-searching-header">
                                <span className="overview-datepicker">
                                    <DateRangePicker
                                        value={dateRange}
                                        onChange={(range: DateRange | null) => {
                                            if (range === null) {
                                                // Handle null case if needed
                                                console.error('Date range is null');
                                                return;
                                            }
                                            let [start, end] = range;
                                            let newStartDate = new Date(start);
                                            let newEndDate = new Date(end);

                                            if (newStartDate > newEndDate) {
                                                [newStartDate, newEndDate] = [newEndDate, newStartDate];
                                            }

                                            newStartDate.setHours(8, 0, 0, 0);
                                            newEndDate.setHours(17, 0, 0, 0);

                                            setOverviewFrom(newStartDate);
                                            setOverviewTo(newEndDate);
                                            setDateRange([newStartDate, newEndDate]);
                                        }}
                                    />
                                </span>
                            </StyledTableCell>
                        </TableRow>
                        <TableRow>
                            <StyledTableCell align="center" className="overview-searching-header">
                                <span>Currently displaying: <span
                                    className="overview-filteroption">{filterOption}</span></span>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="overview-tableBody">
                        <TableRow>
                            <StyledTableCell align="center" className="overview-cell overview-table-header">
                                <span>title</span>
                                <span>status</span>
                                <span className="name-cell">user</span>
                                <span className="date-cell">time</span>
                            </StyledTableCell>
                        </TableRow>
                        {Object.keys(overview).map((key) => (
                            <React.Fragment key={key}>
                                <StyledTableRow>
                                    <StyledTableCell align="center" className="datesegment-cell">{key}</StyledTableCell>
                                </StyledTableRow>
                                {overview[key].map((rowItem) => (
                                    <StyledTableRow key={rowItem.id}>
                                        <StyledTableCell className="overview-cell">
                                            <span>{rowItem.title == null ? "No Title" : rowItem.title}</span>
                                            <span>{rowItem.state === "BOOKED" ? "BOOKED" : "AVAILABLE"}</span>
                                            <span
                                                className="name-cell">{rowItem.name == null ? " " : rowItem.name}</span>
                                            <span className="date-cell">
                                                <div>
                                                    {format(new Date(rowItem.startTime), 'HH:mm')} - {format(new Date(rowItem.endTime), 'HH:mm')}
                                                </div>
                                            </span>
                                        </StyledTableCell>
                                    </StyledTableRow>
                                ))}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
});

export const OverView: React.FC = () => {
    const todayMorning = new Date().setHours(8, 0, 0, 0);
    const todayEvening = new Date().setHours(17, 0, 0, 0);
    const [overview, setOverview] = useState<OverviewState>({});
    const [overviewFrom, setOverviewFrom] = useState<Date>(new Date(todayMorning));
    const [overviewTo, setOverviewTo] = useState<Date>(new Date(todayEvening));
    const [filterOption, setFilterOption] = useState<string>("");
    const [dateRange, setDateRange] = useState<[Date, Date]>([new Date(), new Date()]);
    const {currentUser} = useData();

    const navigate = useNavigate();
    const ref = useRef<HTMLTableElement | null>(null);

    if (
        localStorage.getItem("okta-token-storage") === null ||
        localStorage.getItem("okta-token-storage") === "{}" ||
        JSON.parse(localStorage.getItem("okta-token-storage")!).idToken === undefined
    ) {
        navigate("/");
    }

    const role = currentUser.role;
    useDate(overviewTo.toString(), overviewFrom.toString(), setFilterOption, setOverview);

    if (role === "USER") {
        return <UserRedirect/>;
    } else if (role === "ADMIN") {
        return (
            <AdminView
                ref={ref}
                setOverviewFrom={setOverviewFrom}
                setOverviewTo={setOverviewTo}
                todayEvening={todayEvening}
                filterOption={filterOption}
                overview={overview}
                dateRange={dateRange}
                setDateRange={setDateRange}
            />
        );
    } else {
        return <LoadingView/>;
    }
}

export default OverView;
