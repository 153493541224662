import React from "react";
import './LoginBtn.css'
import logo from './images/Ballys-red.png';

function LoginBtn({login}) {
    return (
        <div className="login-container">
            <div className="login-header">
                <img src={logo} alt="ballys logo" className="bally-logo"></img>
            </div>
            <div className="login-btn-container">
                <h4 className="login-massage-text">
                    Massage
                </h4>
                <button onClick={login} className="login-btn">
                    <h6>Login</h6>
                </button>
            </div>
        </div>
    );
}

export default LoginBtn;