import React from 'react';
import { Modal, Box, Typography } from '@mui/material';
import DangerousOutlinedIcon from '@mui/icons-material/DangerousOutlined';

interface ErrorPopupProps {
    errorMessage: string;
    handleClose: () => void;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const ErrorPopup: React.FC<ErrorPopupProps> = ({ errorMessage, handleClose }) => {
    return (
        <Modal
            open={!!errorMessage}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style} className="pop-up-box">
                <p>
                    <span
                        onClick={handleClose}
                        className="pop-up-close"
                        style={{ cursor: 'pointer', float: 'right' }}
                    >
                        X
                    </span>
                </p>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <em>OBS!</em> <br /> {errorMessage}
                </Typography>
                <DangerousOutlinedIcon className="pop-up-cong-logo error" />
            </Box>
        </Modal>
    );
};

export default ErrorPopup;
