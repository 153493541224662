import React, {useEffect, useState} from "react";
import {styled} from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, {tableCellClasses} from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import "./rolesetting.css";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import {Navigate, useNavigate} from "react-router-dom";
import Logout from "../components/logout/Logout";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import Stack from "@mui/material/Stack";
import jwt_decode from "jwt-decode";
import CircularProgress from "@mui/material/CircularProgress";
import Sidebar from "../components/sidebar/Sidebar";
import Typography from "@mui/material/Typography";
import CelebrationOutlinedIcon from "@mui/icons-material/CelebrationOutlined";
import {sendRequest} from "../components/helper/DataContext";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 375,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
};

const StyledTableCell = styled(TableCell)(({theme}) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.common.black,
        color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 14,
    },
}));

const StyledTableRow = styled(TableRow)(({theme}) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

export default function RoleSetting() {
    const [allUser, setAllUser] = useState([]);
    const [selectedUserName, setSelectedUserName] = useState("");
    const [selectedUserRole, setSelectedUserRole] = useState("");
    const [selectedUserOkta_sub, setSelectedUserOkta_sub] = useState("");
    const [role, setRole] = useState();

    const [search, setSearch] = useState("");
    const [tick, setTick] = useState(0);

    //Success Popup useState
    const [open, setOpen] = useState(false);
    const handleOpen = (okta_sub, role, name) => {
        setSelectedUserName(name);
        setSelectedUserRole(role);
        setSelectedUserOkta_sub(okta_sub);
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    const [successDeleteOpen, setDeleteOpen] = useState(false);
    const handleSuccessDeleteOpen = () => setDeleteOpen(true);
    const handleSuccessDeleteClose = () => setDeleteOpen(false);


    const [successOpen, setSuccessOpen] = useState(false);
    const handleSuccessOpen = () => setSuccessOpen(true);
    const handleSuccessClose = () => setSuccessOpen(false);

    let navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            if (
                localStorage.getItem("okta-token-storage") === null ||
                localStorage.getItem("okta-token-storage") === "{}" ||
                JSON.parse(localStorage.getItem("okta-token-storage")).idToken ===
                undefined
            ) {
                navigate("/");
                return;
            }
            try {
                const response = await sendRequest("/users", "GET", null, navigate)
                setAllUser([]);
                response.data.forEach((item, index) => {
                    setAllUser((prevArray) => [...prevArray, item]);

                    // USERS role
                    if (
                        item.oktaSub ===
                        jwt_decode(
                            JSON.parse(localStorage.getItem("okta-token-storage")).idToken
                                .idToken
                        ).sub
                    ) {
                        setRole(item.role);
                    }
                });
            } catch (error) {
                console.log(error);
            }

        };
        fetchData();
    }, [tick]);


    const changeRoleHandler = async () => {
        const data = JSON.stringify({
            role: `${selectedUserRole === "USER" ? "ADMIN" : "USER"}`,
            oktaSub: selectedUserOkta_sub,
        });


        try {
            await sendRequest("/users", "PUT", data, navigate)
            setTick(tick => tick + 1);
        } catch (error) {
            console.error(error);
        }
    };

    const deleteUser = async (oktaSub) => {
        try {
            await sendRequest(`/users/${oktaSub}`, "delete", navigate)
            setTick(tick => tick + 1);
        } catch (error) {
            console.error(error);
        }
    };


    const renderTable = () => {
        if (search === "") {
            return (
                <>
                    {allUser.map((row) => (
                        <StyledTableRow key={row.name}>
                            <StyledTableCell align="center" className="overview-cell">
                                <div>{row.name}</div>
                                <div>{row.role}</div>
                                <div className="changerole-btn">
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            handleOpen(row.oktaSub, row.role, row.name);
                                        }}
                                        className="roles-button"
                                    >
                                        {row.role === "USER" ? "Make Admin" : "Make User"}
                                    </Button>
                                </div>
                                <div className="deleteuser-btn">
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            deleteUser(row.oktaSub);
                                            handleSuccessDeleteOpen();
                                        }}
                                        className="delete-button"
                                    >
                                        Delete
                                    </Button>
                                </div>
                            </StyledTableCell>
                        </StyledTableRow>
                    ))}
                </>
            );
        } else {
            return (
                <>
                    {allUser
                        .filter((item) =>
                            item.name.toLowerCase().includes(search.toLowerCase())
                        )
                        .map((row) => (
                            <StyledTableRow key={row.name}>
                                <StyledTableCell align="center" className="overview-cell">
                                    <div>{row.name}</div>
                                    <div>{row.role}</div>
                                    <div>
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                handleOpen(row.oktaSub, row.role, row.name);
                                            }}
                                            className="roles-button"
                                        >
                                            Change Role
                                        </Button>
                                    </div>
                                    <div className="deleteuser-btn">
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                deleteUser(row.oktaSub);
                                                handleSuccessDeleteOpen();
                                            }}
                                            className="roles-button"
                                        >
                                            Delete
                                        </Button>
                                    </div>
                                </StyledTableCell>
                            </StyledTableRow>
                        ))}
                </>
            );
        }
    };

    if (role === "USER") {
        return (
            <>
                <Navigate to="/booking" replace={true}/>
            </>
        );
    }

    if (role === "ADMIN") {
        return (
            <div className="App">
                <Sidebar/>
                <Logout/>
                <div className="searchBox">
                    <input
                        className="searchInput"
                        type="text"
                        name=""
                        placeholder="Search"
                        onChange={(e) => setSearch(e.target.value)}
                    />
                    <button className="searchButton">
                        <i className="material-icons">
                            <SearchIcon/>
                        </i>
                    </button>
                </div>
                <h1 className="rolesettings-header">users</h1>
                <TableContainer component={Paper} className="rolesetting-table">
                    <Table sx={{minWidth: 700}} aria-label="customized table">
                        <TableBody>
                            {renderTable()}
                        </TableBody>
                    </Table>
                </TableContainer>

                {/* Pop up which ask for confirmation */}
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="popup"
                >
                    <Box sx={style} className="pop-up-box">
                        <QuestionMarkIcon className="popup-questionmark"/>
                        <div className="table-footer-confirm">
                            Are you sure that you want to change {selectedUserName}'s role?
                            <Stack direction="row" spacing={2}>
                                <div className="cancel-popup">
                                    <Button
                                        className="btn-cancel"
                                        onClick={handleClose}
                                        variant="contained"
                                    >
                                        No
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            changeRoleHandler();
                                            handleClose();
                                            handleSuccessOpen();
                                        }}
                                    >
                                        Yes
                                    </Button>
                                </div>
                            </Stack>
                        </div>
                    </Box>
                </Modal>

                <Modal
                    open={successOpen}
                    onClose={handleSuccessClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="popup"
                >
                    <Box sx={style} className="pop-up-box">
                        <p>
            <span onClick={handleSuccessClose} className="pop-up-close">
              X
            </span>
                        </p>
                        <div>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Role change <br/> was successful!
                            </Typography>
                            <CelebrationOutlinedIcon className="pop-up-cong-logo"/>
                            <Typography
                                className="modal-modal-title"
                                variant="h6"
                                component="h2"
                            ></Typography>
                        </div>
                    </Box>
                </Modal>

                {/*Popup when deleting a user*/}
                <Modal
                    open={successDeleteOpen}
                    onClose={handleSuccessDeleteClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    className="popup"
                >
                    <Box sx={style} className="pop-up-box">
                        <p>
            <span onClick={handleSuccessDeleteClose} className="pop-up-close">
              X
            </span>
                        </p>
                        <div>
                            <Typography id="modal-modal-title" variant="h6" component="h2">
                                Delete <br/> was successful!
                            </Typography>
                            <CelebrationOutlinedIcon className="pop-up-cong-logo"/>
                            <Typography
                                className="modal-modal-title"
                                variant="h6"
                                component="h2"
                            ></Typography>
                        </div>
                    </Box>
                </Modal>
            </div>
        );
    } else {
        return (
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100vh ",
                }}
            >
                <CircularProgress/>
            </Box>
        );
    }
}


