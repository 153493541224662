import React from "react";
import LoginBtn from '../components/login/LoginBtn';
import {useOktaAuth} from '@okta/okta-react';
import {Navigate} from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const Login = () => {
    const {authState, oktaAuth} = useOktaAuth();
    const login = () => oktaAuth.signInWithRedirect({originalUri: "/"});

    if (!authState) {
        return (
            <Box>
                <CircularProgress/>
            </Box>
        );
    } else if (!authState.isAuthenticated) {
        return (
            <>
                <LoginBtn login={login}/>
            </>
        );
    } else {
        return (
            <>
                <Navigate to="/booking" replace={true}/>
            </>
        );
    }
};

export default Login;
