/* eslint-disable no-loop-func */
import React from "react";
import { addDays, format, isSameDay } from "date-fns";
import Appointment from "../appointment/Appointment";
import "./Calendar.css";
import "./CalenderGrid.css";

interface RenderCellsProps {
    startDate: Date;
    endDate: Date;
    appointments: AppointmentItem[];
    onDateClickHandle: (appointment: AppointmentItem) => void;
    changeWeekHandle: (change: number) => void;
}

class CalenderGrid extends React.Component<RenderCellsProps> {
    hasAppointmentsInWeek = (startDate: Date, endDate: Date) => {
        const { appointments } = this.props;
        return appointments.some(appointment => {
            const appointmentDate = new Date(appointment.startTime);
            return appointmentDate >= startDate && appointmentDate <= endDate;
        });
    };

    getWeeksUntilFirstAppointment = (startDate: Date) => {
        const { appointments } = this.props;
        if (appointments.length === 0) return 0;

        const firstAppointmentDate = new Date(appointments[0].startTime);

        const weeksDifference = Math.ceil((firstAppointmentDate.getTime() - startDate.getTime()) / (7 * 24 * 60 * 60 * 1000));
        //If it is the same week it will be +1 and if it is one week after it will show 0 so we need to subtract 1
        return weeksDifference - 1;
    };

    render() {
        let {
            startDate,
            endDate,
            appointments,
            onDateClickHandle,
            changeWeekHandle
        } = this.props;

        const rows: JSX.Element[] = [];
        let days: JSX.Element[] = [];
        let day = startDate;

        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                const cloneDay = day;
                let today = new Date();
                today.setHours(0, 0, 0, 0);
                days.push(
                    <div
                        className={`col col-center calendar-cell ${isSameDay(day, new Date()) ? "today" : ""}`}
                        key={i}
                    >
                        {appointments.map((item) => (
                            isSameDay(new Date(item.startTime), day) && (
                                <Appointment
                                    key={item.id}
                                    allAppointment={appointments}
                                    item={item}
                                    format={format}
                                    cloneDay={cloneDay}
                                    onDateClickHandle={onDateClickHandle}
                                />
                            )
                        ))}
                    </div>
                );
                day = addDays(day, 1);
            }

            rows.push(
                <div className="row" key={day.toString()}>
                    {days}
                </div>
            );
            days = [];
        }




        const hasAppointments = this.hasAppointmentsInWeek(startDate, endDate);
        const weeksUntilFirstAppointment = this.getWeeksUntilFirstAppointment(startDate);


        return (
            <div className="body calendar-body">
                {rows}
                {(!hasAppointments && weeksUntilFirstAppointment !== 0) && (
                    <button
                        className="skip-button"
                        onClick={() => changeWeekHandle(weeksUntilFirstAppointment)}
                    >
                        Go to first available appointment
                    </button>
                )}
            </div>
        );
    }
}

export default CalenderGrid;
