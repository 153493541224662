import { Dispatch, SetStateAction, useEffect } from "react";
import { useData } from "./DataContext";

export type OverviewState = Record<string, Appointment[]>;
type SetOverviewType = Dispatch<SetStateAction<OverviewState>> | ((prevState: OverviewState) => OverviewState);
interface Appointment {
    startTime: string;
    [key: string]: any; // Additional properties can be added as needed
}

function useDate(
    overviewTo: string,
    overviewFrom: string,
    setFilterOption: (option: string) => void,
    setOverview: SetOverviewType) {
    const { fetchAppointments } = useData();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response: Appointment[] = await fetchAppointments(
                    new Date(overviewFrom).toISOString(),
                    new Date(overviewTo).toISOString()
                );

                setFilterOption(
                    `${new Date(overviewFrom).toDateString().slice(3)} - ${new Date(overviewTo).toDateString().slice(3)}`
                );
                const sortedData = response.sort(
                    (a, b) => Date.parse(a.startTime) - Date.parse(b.startTime)
                );

                const result = sortedData.reduce((acc: { [key: string]: Appointment[] }, row) => {
                    const date = new Date(row.startTime).toLocaleDateString("sv");
                    if (!acc[date]) acc[date] = [];
                    acc[date].push(row);
                    return acc;
                }, {});

                setOverview(result);
            } catch (error) {
                console.log(error);
            }
        };

        fetchData();
    }, [overviewFrom, overviewTo, setFilterOption, setOverview, fetchAppointments]);
}

export default useDate;
