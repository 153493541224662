import React, {useEffect, useRef, useState} from "react";
import {useOktaAuth} from "@okta/okta-react";
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Calendar from "../components/calendar/Calendar";
import {useNavigate} from "react-router-dom";
import "./booking.css";
import TableFooter from "../components/table/tableFooter/TableFooter";
import Logout from "../components/logout/Logout";
import {ContextRole} from "../components/helper/Context";
import Sidebar from "../components/sidebar/Sidebar";
import useCalenderAppointments from "../components/helper/useCalenderAppointments";
import {useData} from "../components/helper/DataContext";
import TradeRequest from "../components/traderequests/TradeRequest";
// @ts-ignore
import {EventSourcePolyfill} from "event-source-polyfill";
import {getFormattedStartTime} from "../components/helper/Utils";

interface BookingProps {
}

const Booking: React.FC<BookingProps> = () => {
    const {authState, oktaAuth} = useOktaAuth();
    const [userInfo, setUserInfo] = useState<User | null>(null);
    const [allAppointment, setAllAppointment] = useState<AppointmentItem[]>([]);
    const [tick, setTick] = useState<number>(0);
    /**
     * In case the SSE emitter does not work correctly which can be the case if network configurations are not set up correctly
     * we still want to give live updates in some way. This is why we use a polling mechanism as a fallback.
     */
    const [shouldUsePolling, setShouldUsePolling] = useState<boolean>(true);
    /**
     * If Polling is used this is set to true every five seconds to fetch new data
     * The data is only fetched if there is a user interaction
     */
    let updateAppointments = false;

    const eventSourceRef = useRef<EventSourcePolyfill | null>(null);


    const handleBookingWindowClosed = () => {
        if (!shouldUsePolling) {
            return;
        }

        setTick((prev) => prev + 1);
    }

    const {currentUser, fetchUser} = useData();


    let isFirstRun : boolean = true;
    useEffect(() => {

        if (currentUser && currentUser.oktaSub) {
            return;
        }

        if (isFirstRun) {
            isFirstRun = false;
            return;
        }

        console.log("User is null")


        //Call fetchuser with a 1 second delay to ensure that the user is fetched
        setTimeout(() => {
            console.log("Refetching user")
            fetchUser();
            setTick((prev) => prev + 1);
        }, 1000);



    }, [currentUser]);
    const role: string = currentUser.role;

    //get today's date in ISOString form
    let todaysDate = new Date();
    let from = todaysDate.toISOString();


    let navigate = useNavigate();

    // Calendar
    const [data, setData] = useState<[string, AppointmentItem | null]>(["", null]);

    const showDetailsHandle = (appointment: AppointmentItem) => {
        setData([getFormattedStartTime(appointment), appointment]);
    };


    // Calendar
    useCalenderAppointments({navigate, from, setAllAppointment, tick});


    useEffect(() => {
        // If the polling mechanism is used, we want to poll every 5 seconds
        if (shouldUsePolling) {
            const intervalId = setInterval(async () => {
                updateAppointments = true;
            }, 5000);

            return () => clearInterval(intervalId);
        }
    }, [shouldUsePolling]);

    const startEventSource = () => {
        if (eventSourceRef.current) {
            eventSourceRef.current.close();
        }

        const passedToken = JSON.parse(localStorage.getItem("okta-token-storage") || "{}");
        if (!passedToken || passedToken.isEmpty || !passedToken.idToken || !passedToken.idToken.idToken) {
            navigate("/")
            return;
        }

        let timerId = setTimeout(() => {
            // Code to execute if more than 8 seconds
            console.log("Request for SSE emitter took more than 8 seconds. Switching to polling");
            setShouldUsePolling(false);
            setShouldUsePolling(true);

        }, 8000);

        const eventSource = new EventSourcePolyfill(
            process.env.REACT_APP_API_URL + "/appointments/stream",
            {
                headers: {
                    Authorization: `Bearer ${
                        passedToken
                            .idToken.idToken
                    }`,
                    Accept: "text/event-stream",
                },
            }
        );

        eventSource.onopen = () => {
            // Clear the timer when the request is completed
            clearTimeout(timerId);
            setShouldUsePolling(false);
        };

        eventSource.onmessage = (event: any) => {
            //This is the heartbeat message, it is sent every 30 seconds to keep the connection alive
            if (event.data === "{}") {
                return;
            }

            const updatedAppointment = JSON.parse(event.data);

            setAllAppointment((prevAppointments) => {
                const index = prevAppointments.findIndex(
                    (b) => b.id === updatedAppointment.id
                );

                if (index !== -1) {
                    // Update existing appointment
                    const updatedAppointments = [...prevAppointments];
                    updatedAppointments[index] = updatedAppointment;
                    return updatedAppointments;
                } else {
                    // Create a new appointment
                    const updatedAppointments = [
                        ...prevAppointments,
                        updatedAppointment,
                    ];
                    updatedAppointments.sort((a, b) =>
                        a.startTime.localeCompare(b.startTime)
                    );
                    return updatedAppointments;
                }
            });
        };

        eventSource.onerror = (err: any) => {
            console.error("EventSource failed: ", err);
            eventSource.close();

            if (err.status === 503) {
                console.error("Service unavailable, retrying in 5 seconds...");
                setTimeout(startEventSource, 5000);
            }
        };

        eventSourceRef.current = eventSource;
    };

    const handleUserInteraction = () => {


        if (shouldUsePolling) {
            if (updateAppointments) {
                setTick((prev) => prev + 1);
                updateAppointments = false;
            }
            return;
        }

        if (eventSourceRef.current &&
            eventSourceRef.current.readyState === EventSource.CLOSED
        ) {
            startEventSource();
            setTick((prev) => prev + 1);
        }
    };

    useEffect(() => {
        startEventSource();

        const events = [
            "click",
            "mousemove",
            "keydown",
            "scroll",
            "touchstart",
        ];
        events.forEach((event) =>
            window.addEventListener(event, handleUserInteraction)
        );

        return () => {
            if (eventSourceRef.current) {
                eventSourceRef.current.close();
            }
            events.forEach((event) =>
                window.removeEventListener(event, handleUserInteraction)
            );
        };
    }, []);



    useEffect(() => {
        let timer: NodeJS.Timeout | undefined;

        if (!authState || !authState.isAuthenticated) {
            // When user isn't authenticated, forget any user info
            setUserInfo(null);

            // Start a timer to navigate after 2.521 seconds
            timer = setTimeout(() => {
                navigate('/');
            }, 2521);
        } else {
            // Clear the timer if the user becomes authenticated
            if (timer) {
                clearTimeout(timer);
            }
            oktaAuth.getUser().then((info: any) => {
                setUserInfo(info);
            });
        }

        // Clean up the timer on unmount or when authState changes
        return () => {
            if (timer) {
                clearTimeout(timer);
            }
        };
    }, [authState, oktaAuth, navigate]);


    if (!userInfo) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }


    return (
        <div className="App">
            <ContextRole.Provider value={role}>
                <TradeRequest/>
                <Logout/>
                <Sidebar/>
                <h1>BOOKINGS</h1>
                <Calendar
                    showDetailsHandle={showDetailsHandle}
                    allAppointment={allAppointment}
                />
            </ContextRole.Provider>
            <br/>
            <TableFooter
                dataDB={data}
                allAppointment={allAppointment}
                setAppointmentBooked={handleBookingWindowClosed}
            />
        </div>
    );
};

export default Booking;
