import React, {useEffect, useState} from 'react';
import {addDays, format} from 'date-fns';

interface CalenderHeaderProps {
    weekView: boolean;
    startDate: Date;
    endDate: Date;
}

const CalenderHeader: React.FC<CalenderHeaderProps> = ({weekView, startDate, endDate}) => {
    const dateFormatD = "EEE";
    const dateFormat = "d";
    const [isSticky, setIsSticky] = useState(false);
    const [headerHeight, setHeaderHeight] = useState(0);

    useEffect(() => {
        const header = document.getElementById('calendar-header');
        let lastHeaderSize = 0;

        if (header) {
            lastHeaderSize = header.offsetTop + header.clientHeight;
            setHeaderHeight(header.clientHeight);
        }

        const handleScroll = () => {
            if (header) {
                setIsSticky(window.scrollY > lastHeaderSize);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const rows: JSX.Element[] = [];
    let days: JSX.Element[] = [];
    let day = startDate;
    let formattedDate = "";

    if (weekView) {
        while (day <= endDate) {
            for (let i = 0; i < 7; i++) {
                formattedDate = format(day, dateFormat);
                let today = new Date();
                today.setHours(0, 0, 0, 0);
                days.push(
                    <div className="col" key={day.toString()}>
                        <div className={`date-container`}>
                            <p className={day < today ? "days-name-faded" : "days-name"}>
                                {format(addDays(startDate, i), dateFormatD)}
                            </p>
                            <span className={day < today ? "number-faded" : "number"}>{formattedDate}/</span>
                            <span className={day < today ? "months-faded" : "months"}>{day.getMonth() + 1}</span>
                        </div>
                    </div>
                );
                day = addDays(day, 1);
            }

            rows.push(
                <div key={day.toString()}>
                    <div className={`row calendar-header ${isSticky ? 'fixed' : ''}`} id="calendar-header">
                        {days}
                    </div>
                    {isSticky && <div style={{ height: headerHeight }}></div>}
                </div>
            );
            days = [];
        }
        return <div className="body">{rows}</div>;
    } else {
        return <div className="date-container list-header">List view</div>;
    }
};

export default CalenderHeader;
