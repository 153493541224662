import "./App.css";
import React from "react";
import Booking from "./pages/Booking";
// @ts-ignore
import Login from "./pages/Login";
// @ts-ignore
import Admin from "./pages/Admin";
import {Route, Routes, useNavigate} from "react-router-dom";
// @ts-ignore
import {oktaConfig} from "./lib/oktaConfig";
import {OktaAuth, toRelativeUrl} from "@okta/okta-auth-js";
import {LoginCallback, Security} from "@okta/okta-react";
import OverView from "./pages/OverView";
// @ts-ignore
import RoleSetting from "./pages/RoleSetting";
import {ContextapiUrl} from "./components/helper/Context";
import {DataProvider} from './components/helper/DataContext';

const oktaAuth = new OktaAuth(oktaConfig);

const App: React.FC = () => {
    const history = useNavigate();

    const restoreOriginalUri = async (_oktaAuth: OktaAuth, originalUri: string) => {
        history(toRelativeUrl(originalUri || "/home", window.location.origin), {
            replace: true,
        });
    };


    return (
        <>
            <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
                <ContextapiUrl.Provider value={process.env.REACT_APP_API_URL || ''}>
                    <Routes>
                        <Route path="/" element={<Login/>}/>
                        <Route path="/login/callback" element={<LoginCallback/>}/>
                        <Route path="/booking" element={
                            <DataProvider>
                                <Booking/>
                            </DataProvider>
                        }/>
                        <Route path="/admin" element={
                            <DataProvider>
                                <Admin/>
                            </DataProvider>
                        }/>
                        <Route path="/overview" element={
                            <DataProvider>
                                <OverView/>
                            </DataProvider>
                        }/>
                        <Route path="/rolesetting" element={
                            <DataProvider>
                                <RoleSetting/>
                            </DataProvider>
                        }/>
                    </Routes>
                </ContextapiUrl.Provider>
            </Security>
        </>
    );
}

export default App;
