// Any future paths for navigation in the sidebar can be added / removed here for easy implementation

export const navigationButtons: NavigateButton[] = [
    {
        title: "BOOKINGS",
        path: "/booking"
    }
];

export const adminButtons: NavigateButton[] = [
    {
        title: "CREATE",
        path: "/admin"
    },
    {
        title: "USERS",
        path: "/rolesetting"
    },
    {
        title: "OVERVIEW",
        path: "/overview"
    }
];
