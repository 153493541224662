/* eslint-disable no-loop-func */
import React, {useEffect, useRef, useState} from "react";
import {addWeeks, getWeek, lastDayOfWeek, startOfWeek,} from "date-fns";
import "./Calendar.css";
import CalenderButtons from "./CalenderButtons";
import CalenderHeader from "./CalendarHeader";
import CalenderGrid from "./CalenderGrid";
import CalendarList from "./CalendarList";
import {useNavigate} from "react-router-dom";

interface CalendarProps {
    showDetailsHandle: (appointment: AppointmentItem) => void;
    allAppointment: AppointmentItem[];
}

const Calendar: React.FC<CalendarProps> = (props) => {
    const {
        showDetailsHandle,
        allAppointment,
    } = props;
    const [currentMonth, setCurrentMonth] = useState<Date>(new Date());
    const [currentWeek, setCurrentWeek] = useState<number>(getWeek(currentMonth));
    const startDate = startOfWeek(currentMonth, {weekStartsOn: 1});
    const endDate = lastDayOfWeek(currentMonth, {weekStartsOn: 1});
    const [weekView, setWeekView] = useState<boolean>(true);
    const [filtered, setFiltered] = useState<boolean>(false);
    const [activeButton, setActiveButton] = useState<string>("week");


    const changeWeekHandle = (num: number) => {
        setCurrentMonth(addWeeks(currentMonth, num));
        setCurrentWeek(getWeek(addWeeks(currentMonth, num)));
    };

    const onWeekViewClickHandle = () => {
        setWeekView(true);
        setActiveButton("week");
    };

    const onListViewClickHandle = () => {
        setWeekView(false);
        setActiveButton("list");
    };

    //only show list view on small screens
    if (weekView && window.innerWidth < 800) {
        setWeekView(false);
    }

    //Remove all Appointments that have the state "DELETED"
    let filteredAppointments = allAppointment.filter(
        (appointment) => appointment.state !== "DELETED"
    );

    //If the filter is active, only show appointments that are ACTIVE
    if (filtered) {
        filteredAppointments = filteredAppointments.filter(
            (appointment) => appointment.state === "ACTIVE"
        );
    }

    return (
        <div className="calendar">
            <CalenderButtons
                weekView={weekView}
                currentWeek={currentWeek}
                startDate={startDate}
                endDate={endDate}
                changeWeekHandle={changeWeekHandle}
                activeButton={activeButton}
                onWeekViewClickHandle={onWeekViewClickHandle}
                onListViewClickHandle={onListViewClickHandle}
                setFilterAppointments={setFiltered}
            />
            <CalenderHeader
                weekView={weekView}
                startDate={startDate}
                endDate={endDate}
            />
            {weekView ? (
                <CalenderGrid
                    startDate={startDate}
                    endDate={endDate}
                    appointments={filteredAppointments}
                    onDateClickHandle={showDetailsHandle}
                    changeWeekHandle={changeWeekHandle}
                />
            ) : (
                <CalendarList
                    appointments={filteredAppointments}
                    onDateClickHandle={showDetailsHandle}
                />
            )}
        </div>
    );
};

export default Calendar;
