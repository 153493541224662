import { format } from "date-fns";

const getFormattedStartTime = (appointment: AppointmentItem) => {
    return format(new Date(appointment.startTime), "ccc dd MMM yy HH:mm");
}

const getFormattedEndTime = (appointment: AppointmentItem) => {
    return format(new Date(appointment.endTime), "ccc dd MMM yy HH:mm");
}

const getFormattedExpiredTime = (expiredTime: string) => {
    const date = new Date(expiredTime.toString()); // Ensuring it's in ISO format
    return format(date, "ccc dd MMM yy HH:mm");
}

export { getFormattedStartTime, getFormattedEndTime, getFormattedExpiredTime };
