const {
    REACT_APP_CLIENT_ID, 
    REACT_APP_OKTA_DOMAIN, 
    REACT_APP_REDIRECT_URI
} = process.env;
console.log(REACT_APP_CLIENT_ID);
console.log(REACT_APP_OKTA_DOMAIN);
console.log(REACT_APP_REDIRECT_URI);

export const oktaConfig = {
    clientId: `${REACT_APP_CLIENT_ID}`,
    issuer: `${REACT_APP_OKTA_DOMAIN}`,
    redirectUri: `${REACT_APP_REDIRECT_URI}`,
    // this makes it so redirects to login if not logged in for secure routes
    scopes: ["openid", "profile", "email"],
    pkce: true,
    disableHttpsCheck: true,
};