import React, {useContext} from "react";
import {ContextRole} from "../helper/Context";
import jwt_decode from "jwt-decode";
import {useData} from "../helper/DataContext";

interface AppointmentProps {
    item: AppointmentItem;
    format: (date: Date, formatString: string) => string;
    cloneDay: Date;
    onDateClickHandle: (appointment: AppointmentItem) => void;
    allAppointment: AppointmentItem[];
}


const Appointment: React.FC<AppointmentProps> = ({
                                                     item,
                                                     format,
                                                     cloneDay,
                                                     onDateClickHandle,
                                                     allAppointment,
                                                 }) => {
    const role = useContext(ContextRole);
    const hoursToStringEnd: string = new Date(item.endTime).getHours().toString();
    const minutesToStringEnd: string = new Date(item.endTime).getMinutes().toString();

    const hoursToStringStart: string = new Date(item.startTime).getHours().toString();
    const minutesToStringStart: string = new Date(item.startTime).getMinutes().toString();

    const hours_slicedStart: string = ("0" + hoursToStringStart).slice(-2);
    const minutes_slicedStart: string = ("0" + minutesToStringStart).slice(-2);

    const hours_slicedEnd: string = ("0" + hoursToStringEnd).slice(-2);
    const minutes_slicedEnd: string = ("0" + minutesToStringEnd).slice(-2);

    const sessionStart: string = `${hours_slicedStart}:${minutes_slicedStart}`;
    const sessionEnd: string = `${hours_slicedEnd}:${minutes_slicedEnd}`;

    const startTimeValue: number = new Date(item.startTime).getTime();
    const endTimeValue: number = new Date(item.endTime).getTime();
    const timeDiff: number = endTimeValue - startTimeValue;

    // difference in minutes
    let minutes: number = Math.round(timeDiff / (1000 * 60));
    // 15 min and less sessions are set to default height&padding
    if (minutes <= 15) {
        minutes = 0;
    }

    const {currentUser}: { currentUser: User } = useData();

    const oktaSub = currentUser.oktaSub;
/*    const oktaSub = (jwt_decode(
        JSON.parse(localStorage.getItem("okta-token-storage") || "{}").idToken.idToken
    ) as { sub: string }).sub;*/

    // "Color-tag" to decide availability
    let tagColor;
    if (item.oktaSub === oktaSub && item.state === "BOOKED") {
        //if its your booking -> green
        tagColor = "#00FF4F";
    } else if (item.state === "BOOKED") {
        tagColor = "#FF2B00";
    } else {
        tagColor = "#28C3FF";
    }

    // If in the future, if titles want to be put back add this: <div className="title">{item.title == null ? "" : item.title}</div>

    return (
        <div
            // Handler for when a user selects an appointment
            onClick={() => {
                allAppointment.forEach((appointment) => {
                    appointment.selected = false;
                });
                item.selected = true;
                onDateClickHandle(item);
            }}
            style={{paddingBottom: `${minutes}px`}}
            // If an appointment gets booked it will display as booked.
            className={`appointment_time ${item.selected ? "selected" : ""} ${item.state === "BOOKED" ? "booked" : ""}`}
        >
            <div className="tag" style={{backgroundColor: `${tagColor}`}}></div>
            <div className="appointmentContent">
                <div className="time">{sessionStart}-{sessionEnd}</div>
                <div className="name">{item.name}</div>
            </div>
        </div>
    );
};

export default Appointment;
