import React, {Dispatch, SetStateAction, useEffect, useState, useRef} from "react";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import "./TableFooter.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CelebrationOutlinedIcon from "@mui/icons-material/CelebrationOutlined";
import {sendRequest, useData} from "../../helper/DataContext";
import DeleteButton from "./DeleteButton";
import ErrorPopup from "./ErrorPopup";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TradeRequestInfo from "./TradeRequestInfo";
import {useNavigate} from "react-router-dom";


const style = {
    position: "absolute" as const,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 375,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
};

interface TableFooterProps {
    dataDB: [string, AppointmentItem | null];
    allAppointment: AppointmentItem[];
    //function
    setAppointmentBooked: () => void;
}
const AddCalendar: React.FC<{
    eventTitle: string;
    eventLocation: string;
    startTime?: string;
    endTime?: string;
    appointmentEvent?: string;
  }> = ({ eventTitle, eventLocation, startTime, endTime, appointmentEvent }) => {
    return (
      <>
        <Button
          variant="contained"
          className="btn-addcalendar"
          onClick={() => {
            window.open(
              `https://outlook.office.com/calendar/0/deeplink/compose?body=Massage&subject=${eventTitle}&location=${eventLocation}&startdt=${startTime}&enddt=${endTime}`
                );
          }}
        >
          Add to Outlook
        </Button>
          <a
            href={`data:text/calendar;charset=utf-8,${appointmentEvent}`}
            className="ics-confirm"
          >
            Download ICS
          </a>
      </>
    );
  };

const TableFooter: React.FC<TableFooterProps> = ({
                                                     dataDB,
                                                     allAppointment,
                                                     setAppointmentBooked
                                                 }) => {
    const appointmentEvent = useRef<string>();
    let eventTitle: string = "massage";
    const eventLocation: string = "Bally's massage room";
    let organizerMail: string | null = null;
    const {currentUser, updateAppointment}: { currentUser: User; updateAppointment: Function } = useData();

    //confirm Popup useState
    const [openConfirmPopUp, setOpenConfirmPopUp] = useState<boolean>(false);
    const handleCloseConfirmPopUp = () => {
        setOpenConfirmPopUp(false);
        setSelectedAppointment(null);
        setAppointmentBooked();
    }


    // Success Popup useState
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
    };

    // Successful Delete Popup useState
    const [openDeletePopUp, setOpenDeletePopUp] = useState(false);
    const handleOpenDeletePopUp = () => setOpenDeletePopUp(true);
    const handleCloseDeletePopUp = () => {
        setOpenDeletePopUp(false);
    };

    const [errorMessage, setErrorMessage] = useState("");
    const setEventInfo = (response: any) => {
        if (response?.data?.appointmentEvent) {
            appointmentEvent.current = response.data.appointmentEvent;
            if (typeof appointmentEvent.current === "string") {
                const matchResult = appointmentEvent.current.match(/ORGANIZER;.*:mailto:([^;]+)/);
                if (matchResult) {
                    organizerMail = matchResult[1];
                }
            }
        }

    };  
    const notifyOnSuccess = (message: string) => {
        toast.success(message);
    };


    // Successful cancellation Popup useState
    const [openCancelPopup, setOpenCancelPopup] = useState(false);
    const handleCancellationOpen = () => setOpenCancelPopup(true);
    const handleCancellationClose = () => setOpenCancelPopup(false);

    /**
     * Selected appointment for trade request
     */
    const [selectedAppointment, setSelectedAppointment] = useState<AppointmentItem | null>(null);
    const [message, setMessage] = useState("");
    const [expiredTime, setExpiredTime] = useState("");


    // When booking an appointment
    const bookingAppointmentHandler = async () => {
        const requestData = {...dataDB[1]};
        if (!requestData) return;
        requestData.oktaSub = currentUser.oktaSub;
        requestData.state = "BOOKED";
        const onSuccess = (response: any) => {
            if (response.status === 200) {
                handleCloseConfirmPopUp();
                handleOpen();
                setEventInfo(response);
            }
        };

        const onError = (error: any) => {
            if (error.response.status === 403) {
                handleCloseConfirmPopUp();
                setErrorMessage(error.response.data.message);
            } else if (error.response.status === 400) {
                handleCloseConfirmPopUp();
                setErrorMessage(error.response.data.message);
            }
        };

        try {
            await updateAppointment("PUT", requestData, onSuccess, onError);
        } catch (error) {
            console.error("Unexpected error:", error);
        }
    };


    const [isFirstOpen, setIsFirstOpen] = useState(true);
    useEffect(() => {

        if (isFirstOpen) {
            setIsFirstOpen(false)
            return;
        }


        setOpenConfirmPopUp(true)
    }, [dataDB]);

    // When reverting booking
    const cancellationAppointmentHandler = async () => {
        const requestData = {...dataDB[1]};
        if (!requestData) return;
        requestData.oktaSub = currentUser.oktaSub;
        requestData.state = "ACTIVE";

        const onSuccess = (response: any) => {
            handleCloseConfirmPopUp();
            handleCancellationOpen();
            setEventInfo(response);
        };

        const onError = (error: any) => {
            console.error("Unexpected cancelling a booking:", error);
        };

        try {
            await updateAppointment("PUT", requestData, onSuccess, onError);
        } catch (error) {
            console.error("Unexpected error when deleting a booking:", error);
        }
    };

    // When deleting an appointment
    const deletionAppointmentHandler = async () => {
        const requestData = {...dataDB[1]};
        if (!requestData) return;
        requestData.state = "DELETED";

        const onSuccess = (response: any) => {
            handleCloseConfirmPopUp();
            notifyOnSuccess("The appointment was successfully removed.");
        };

        const onError = (error: any) => {
            console.log(error.response.status);
            if (error.response.status === 403) {
                handleCloseConfirmPopUp();
                handleOpenDeletePopUp();
            } else if (error.response.status === 400) {
                handleCloseConfirmPopUp();
                handleOpenDeletePopUp();
            }
        };

        try {
            await updateAppointment("PUT", requestData, onSuccess, onError);
        } catch (error) {
            console.error("Unexpected error when deleting a booking:", error);
        }
    };

    const navigate = useNavigate;

    const sendTradeRequest = async () => {
        const requestData = {...dataDB[1]};
        if (!requestData) return;

        let tradeRequestData = {
            requestedAppointment: dataDB[1],
            offeredAppointment: selectedAppointment,
            offeree: currentUser,
            message: message,
            expiredTime: expiredTime,
        };


        try {
            await sendRequest("/trades", "POST", JSON.stringify(tradeRequestData), navigate);
            handleCloseConfirmPopUp();
            notifyOnSuccess("Trade request sent.");
        } catch (error: any) {
            handleCloseConfirmPopUp();
            setErrorMessage(error.response.data.message);
            console.error("Unexpected error:", error);
        }
    }

    const role = currentUser.role;


    const renderButtons = () => {
        if (!dataDB[1]) {
            return null;
        }

        let confirmAction: () => void;
        switch (dataDB[1].state) {
            case "ACTIVE":
                confirmAction = bookingAppointmentHandler;
                break;
            case "BOOKED":
                confirmAction = dataDB[1].oktaSub === currentUser.oktaSub ? confirmAction = cancellationAppointmentHandler : sendTradeRequest;
                break;
            default:
                return null;
        }

        return (
            <>
                <div className="confirm-popup">
                    <DeleteButton role={role} deleteAction={deletionAppointmentHandler}
                                  prevModal={handleCloseConfirmPopUp}/>
                    {role === 'ADMIN' && dataDB[1].oktaSub != currentUser.oktaSub && dataDB[1].state === 'BOOKED' && (
                        <Button variant="contained" id="btn-unbook" onClick={cancellationAppointmentHandler}>
                            Unbook
                        </Button>
                    )}
                    <Button variant="contained" className="btn-cancel" onClick={handleCloseConfirmPopUp}>
                        No
                    </Button>
                    <Button variant="contained" className="btn-confirm" onClick={confirmAction}>
                        Yes
                    </Button>
                    {dataDB[1].oktaSub === currentUser.oktaSub && dataDB[1].state === 'BOOKED' && (
                    <div className="cancel-popup-secondary-buttons">
                                      <AddCalendar
                eventTitle={eventTitle}
                eventLocation={eventLocation}
                startTime={dataDB[1]?.startTime}
                endTime={dataDB[1]?.endTime}
                appointmentEvent={appointmentEvent.current}
              />
                    </div>
                )}
                </div>

            </>
        );

    }


    const renderPopupTex = () => {
        let text: string = "";
        let sendReq: boolean = false;
        switch (dataDB[1]?.state) {
            case "ACTIVE":
                text = "Do you want to confirm booking ";
                break;
            case "BOOKED":
                if (dataDB[1]?.oktaSub === currentUser.oktaSub) {
                    text = "Do you want to cancel booking ";
                    break;
                }
                text = "Do you want to send a trade request for ";
                sendReq = true;
                break;
            default:
                text = "Error! Wrong type ";
        }

        return (
            <span className="detail-sec">
                {text} {dataDB[0]}?

                {sendReq && <TradeRequestInfo
                    allAppointment={allAppointment}
                    selectedAppointment={selectedAppointment}
                    setSelectedAppointment={setSelectedAppointment}
                    setExpiredTime={setExpiredTime}
                    setText={setMessage}
                    />}
            </span>
        )
    }

    return (
        <div className="table-footer-container">
            {/* Booking confirm popup */}
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description" className="popup">
                <Box sx={style} className="pop-up-box">
                    <p>
            <span onClick={handleClose} className="pop-up-close">
              X
            </span>
                    </p>
                    <div>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            Your booking <br/> was confirmed!
                        </Typography>
                        <CelebrationOutlinedIcon className="pop-up-cong-logo"/>
                        <Typography className="modal-modal-title" variant="h6" component="h2"></Typography>
                        <AddCalendar
                eventTitle={eventTitle}
                eventLocation={eventLocation}
                startTime={dataDB[1]?.startTime}
                endTime={dataDB[1]?.endTime}
                appointmentEvent={appointmentEvent.current}
              />

                    </div>
                </Box>
            </Modal>

            {/* Cancel popup */}
            <Modal open={openCancelPopup} onClose={handleCancellationClose} aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description" className="popup">
                <Box sx={style} className="pop-up-box">
                    <p>
            <span onClick={handleCancellationClose} className="pop-up-close">
              X
            </span>
                    </p>
                    <div>
                        <Typography id="modal-modal-title" variant="h6" component="h2">
                            The booking <br/> was cancelled!
                        </Typography>
                        <CelebrationOutlinedIcon className="pop-up-cong-logo"/>
                        <div>Don't forget to remove the  booking from your calendar!</div>
                        <a href={`data:text/calendar;charset=utf-8,${appointmentEvent.current}`} className="ics-confirm"> Download cancel ICS
                        </a>
                    </div>
                </Box>
            </Modal>

            {/* Pop up which ask for confirmation */}
            <Modal open={openConfirmPopUp} onClose={handleCloseConfirmPopUp} aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description" className="popup">
                <Box sx={style} className="pop-up-box">
                    <div className="table-footer-confirm">
                        {renderPopupTex()}


                        <Stack direction="row" spacing={2}>
                            {renderButtons()}
                        </Stack>
                    </div>
                </Box>
            </Modal>

            {/* Successful delete popup */}
            <Modal open={openDeletePopUp} onClose={handleCloseDeletePopUp} aria-labelledby="modal-modal-title"
                   aria-describedby="modal-modal-description">
                <Box sx={style} className="pop-up-box">
                    <p>
            <span onClick={handleCloseDeletePopUp} className="pop-up-close">
              X
            </span>
                    </p>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        You successfully DELETED <br/> the appointment
                    </Typography>
                    <CelebrationOutlinedIcon className="pop-up-cong-logo"/>
                </Box>
            </Modal>

            {/* Personal double booking error */}
            {/* Error to show appointment is booked by someone else and for booking limitations */}
            <ErrorPopup errorMessage={errorMessage} handleClose={() => setErrorMessage("")}/>
            <ToastContainer></ToastContainer>
        </div>
    );
};

export default TableFooter;
